html, body { padding:0; margin:0; width:100%; height:100vh; }
.App { width:100%; height:100%; }
#first { width:100%; height:100vh; background:#000; color:#FFF; font-size:2.5rem; }
.page-base { width:100%; height:100vh; }
#first::before { 
    position: absolute;
    top:0;
    left:0;
    background:#000;
    background-size:cover;
    width:100%;
    height:100vh;
    background-blend-mode:luminosity;
    z-index: 0;
    position: absolute;
    content:"";
    opacity:.25;
}
#first .content { position: relative; z-index:1; }

.logoFixed { position: absolute; top:5rem; width:100%; text-align: center;}
.logoFixed img { height:10vh; }

.startscreen { width:100%; min-height:70vh; margin-top:10vh;  border-radius: 40px; overflow: hidden; display: flex; align-items:center; justify-content: center; }

h2 { font-size:1.5rem; }
.btn { border-radius:30px; }
.btn.btn-none { background:transparent !important; padding:0 !important; height:auto !important; width:auto !important; margin:0 !important; border-radius: 0 !important;}
.btn-outline { background:none; border:1px solid #fff; border-radius: 100%; color:#FFF; font-size:2.5rem; font-weight: 500; font-style: italic; width:5rem; height:5rem; }
.page-base .btn { background:black; color:white; transition: all 1.5 linear; border-radius:10px; width:100%; height:60px; font-size:1.5rem;}
.page-base .btn:disabled {opacity: 1;}
.page-base .btn.correct:disabled { background:green;}
.page-base .btn.wrong:disabled { background:red;} 
.page-base { position: relative;}
.page-base .card { padding:50px; }
.page-base .btn-icon { position: absolute; bottom:-30px; left:50%; margin-left:-27px; border-radius:100%; width:55px; height:55px; padding:0; }
.page-base .btn-icon img { width:100%; height:auto; }

.rounded-box { border:2px solid #000; border-radius: 100%; width:34px; height:34px; display:inline-block; text-align: center; display: inline-flex; align-items: center; justify-content: center;} 
.rounded-box.text-success { border-color:green;}
.rounded-box.text-danger { border-color:red;}
.boxed-entities { text-align: center; font-weight: bold; font-style: italic; font-size:1.5rem; line-height:30px; }
.boxed-entities span { width:34px; height:34px; display: inline-block; margin-left:15px; margin-right:15px;}

.results > p > .img-inside { max-height:30vh; }
.card { height:calc(100% - 10vh - 5rem); margin-top:calc(5rem + 10vh); border-radius:30px; }
.results { height:calc(100% - 10vh - 5rem); margin-top:calc(5rem + 10vh); }


.answers { display: grid; grid-gap:2rem; gap:2rem; grid-template-columns:repeat(1,4fr) }

@media (orientation:landscape) {
    .answers { display: grid; grid-gap:1rem; gap:1rem; grid-template-columns:repeat(2,4fr) }

}